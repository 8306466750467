import { useUser } from "lib/context/user-context";
import { useRouter } from "next/router";
import mixpanel from "mixpanel-browser";
import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { config } from "server/config";

export enum MixpanelEvents {
  CLICKED_CREATE_PROJECT = "Clicked Create Project",
  CREATED_PROJECT = "Created Project",
  CREATED_PAGE = "Created Page",
  ADDED_COMPONENT = "Added Component",
  CREATED_ACTION = "Created Action",
  CREATED_FIELD = "Created Field",
  UPDATED_FIELD = "Updated Field",
  CREATED_TABLE = "Created Table",
}

export type MixpanelContextValue = {
  track: (eventName: string, data?: Record<string, any>) => void;
};

const defaultValue: MixpanelContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  track: () => {},
};

export const MixpanelContext =
  React.createContext<MixpanelContextValue>(defaultValue);

export function useMixpanelContext() {
  return React.useContext(MixpanelContext);
}

export const MixpanelContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user } = useUser();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (config().NEXT_PUBLIC_MIXPANEL_PROJECT_ID && !initialized) {
      mixpanel.init(config().NEXT_PUBLIC_MIXPANEL_PROJECT_ID, {
        debug: config().NODE_ENV === "development",
      });
      setInitialized(true);
    }
  }, [initialized]);

  useEffect(() => {
    if (initialized && user?.zapierId) {
      mixpanel.identify(`${user.zapierId}`);
    }
  }, [user?.zapierId, initialized]);

  const value = useMemo(() => {
    const track = (eventName: string, data?: Record<string, any>) => {
      if (!initialized) return;
      mixpanel.track(eventName, data);
    };

    return { track };
  }, [initialized]);
  return (
    <MixpanelContext.Provider value={value}>
      {children}
    </MixpanelContext.Provider>
  );
};

export const MixpanelPageViewTracker = () => {
  const router = useRouter();
  const { track: mixpanelTrack } = useMixpanelContext();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      mixpanelTrack("Viewed Page", {
        url,
      });
    };

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [mixpanelTrack, router.events]);

  return null;
};
