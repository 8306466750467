import { z } from "zod";

const RemSchema = z.string().regex(/^\d+(?:\.\d+)?rem$/i);
const HexSchema = z
  .string()
  .regex(/^#?([0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{6}|[0-9A-F]{8})$/i);

export const InterfacesThemeSchema = z.object({
  radius: RemSchema.default("0.5rem"),
  background: HexSchema.default("#ffffff"),
  foreground: HexSchema.default("#020817"),
  card: HexSchema.default("#ffffff"),
  cardForeground: HexSchema.default("#020817"),
  popover: HexSchema.default("#ffffff"),
  popoverForeground: HexSchema.default("#020817"),
  primary: HexSchema.default("#2563eb"),
  primaryForeground: HexSchema.default("#f8fafc"),
  secondary: HexSchema.default("#f1f5f9"),
  secondaryForeground: HexSchema.default("#0f172a"),
  muted: HexSchema.default("#f1f5f9"),
  mutedForeground: HexSchema.default("#64748b"),
  accent: HexSchema.default("#f1f5f9"),
  accentForeground: HexSchema.default("#0f172a"),
  destructive: HexSchema.default("#ef4444"),
  destructiveForeground: HexSchema.default("#f8fafc"),
  border: HexSchema.default("#e2e8f0"),
  input: HexSchema.default("#e2e8f0"),
  ring: HexSchema.default("#2563eb"),
  darkBackground: HexSchema.default("#020817"),
  darkForeground: HexSchema.default("#f8fafc"),
  darkCard: HexSchema.default("#020817"),
  darkCardForeground: HexSchema.default("#f8fafc"),
  darkPopover: HexSchema.default("#020817"),
  darkPopoverForeground: HexSchema.default("#f8fafc"),
  darkPrimary: HexSchema.default("#3b82f6"),
  darkPrimaryForeground: HexSchema.default("#0f172a"),
  darkSecondary: HexSchema.default("#1e293b"),
  darkSecondaryForeground: HexSchema.default("#f8fafc"),
  darkMuted: HexSchema.default("#1e293b"),
  darkMutedForeground: HexSchema.default("#94a3b8"),
  darkAccent: HexSchema.default("#1e293b"),
  darkAccentForeground: HexSchema.default("#f8fafc"),
  darkDestructive: HexSchema.default("#7f1d1d"),
  darkDestructiveForeground: HexSchema.default("#f8fafc"),
  darkBorder: HexSchema.default("#1e293b"),
  darkInput: HexSchema.default("#1e293b"),
  darkRing: HexSchema.default("#94a3b8"),
});

export type InterfacesTheme = z.infer<typeof InterfacesThemeSchema>;

const HslSchema = z
  .string()
  .regex(/^\d+(?:\.\d+)? \d+(?:\.\d+)?% \d+(?:\.\d+)?%$/i);

export const HslThemeSchema = z.object({
  radius: RemSchema,
  background: HslSchema,
  foreground: HslSchema,
  card: HslSchema,
  cardForeground: HslSchema,
  popover: HslSchema,
  popoverForeground: HslSchema,
  primary: HslSchema,
  primaryForeground: HslSchema,
  secondary: HslSchema,
  secondaryForeground: HslSchema,
  muted: HslSchema,
  mutedForeground: HslSchema,
  accent: HslSchema,
  accentForeground: HslSchema,
  destructive: HslSchema,
  destructiveForeground: HslSchema,
  border: HslSchema,
  input: HslSchema,
  ring: HslSchema,
  darkBackground: HslSchema,
  darkForeground: HslSchema,
  darkCard: HslSchema,
  darkCardForeground: HslSchema,
  darkPopover: HslSchema,
  darkPopoverForeground: HslSchema,
  darkPrimary: HslSchema,
  darkPrimaryForeground: HslSchema,
  darkSecondary: HslSchema,
  darkSecondaryForeground: HslSchema,
  darkMuted: HslSchema,
  darkMutedForeground: HslSchema,
  darkAccent: HslSchema,
  darkAccentForeground: HslSchema,
  darkDestructive: HslSchema,
  darkDestructiveForeground: HslSchema,
  darkBorder: HslSchema,
  darkInput: HslSchema,
  darkRing: HslSchema,
});

export type HslTheme = z.infer<typeof HslThemeSchema>;
